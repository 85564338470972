import React, { useEffect, useState } from "react";
import "@splidejs/react-splide/css";
import { RiArrowRightSLine } from "react-icons/ri";
import SocialMediaSidebar from "./components/SocialMediaSidebar";
import ScrollArrow from "./components/Scrollarrow";
import EnquiryForm from "./components/EnquiryForm";
import Industries from "./Pages/Industries/Industries";
import MissionVisionValues from "./Pages/Missionvision/Missionvision";
import HowWeWork from "./Pages/Howtowork/Howtowork";
import { motion } from "framer-motion";
import Whyworkwithus from "./Pages/Howtowork/whyworkwithus";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import thirdImage from "./components/Assets/BackgroundMain.jpg";
import SecondImage from "./components/Assets/mainbg2.jpg";
import mainImage from "./components/Assets/bgMain2.jpg";
import icon from "../src/components/Assets/arrow-right.png";
import firstimage from "../src/components/Assets/firstaboveimage.jpg";
import Secondimage from "../src/components/Assets/second below image.jpg";
import thirdimage from "../src/components/Assets/third side image.png";
import pointer from "../src/components/Assets/pointers.png";
import checked from "../src/components/Assets/checked.png";
import microphone from "./components/Assets/microphone.png";
import Testmonials from "./components/Testmonials";
import { useNavigate } from "react-router-dom";
import serviceone from "./components/Assets/serviceone.png";
import servicetwo from "./components/Assets/servicetwo.png";
import servicethree from "./components/Assets/servicethree.png";
import servicefour from "./components/Assets/servicefour.png";
import FAQ from "./Pages/FAQ/FAQ";
import axios from "axios";

export default function Home() {
  const Navigate = useNavigate();
  const [activeService, setActiveService] = useState(null);

  useEffect(() => {
    ContactUs();
  }, []);

  const ContactUs = () => {
    axios({
      url: "https://lunarsenterprises.com:5009/choiceglobal/list/contact-info",
      method: "POST",
    })
      .then((response) => {
        if (response.data.result) {
          let data = response.data.list[0];
          localStorage.setItem("email", data.ci_email);
          localStorage.setItem("phone", data.ci_phone_1);
          localStorage.setItem("phone1", data.ci_phone_2);
          localStorage.setItem("address", data.ci_address);
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const toggleContent = (service) => {
    setActiveService(activeService === service ? null : service);
  };
  const slides = [
    {
      title: "Your Premier Staffing Solution in Oman",
      description:
        "Delivering quality talent for your business needs, both permanent and temporary.",
      buttonLabel: "Contact Us",
      buttonLink: "/contactUs",
      backgroundImage: SecondImage,
    },
    {
      title: "Empowering Businesses with Talent",
      description:
        "We connect you with skilled professionals tailored to your requirements.",
      buttonLabel: "Learn More",
      buttonLink: "/service",
      backgroundImage: mainImage,
    },
    {
      title: "Building Careers, Building Futures",
      description: "Join us in creating a workforce that drives success.",
      buttonLabel: "Get Started",
      buttonLink: "/careers",
      backgroundImage: thirdImage,
    },
  ];
  const fadeIn = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };
  const handleNavigate = (serviceTitle) => {
    Navigate(`/service/${encodeURIComponent(serviceTitle)}`);
  };
  const phone = localStorage.getItem("phone");
  const isInteger = /^\+?\d[\d\s-]*$/.test(phone);
  return (
    <>
      <head>
        <title>Aviation</title>
      </head>
      <SocialMediaSidebar />

      <ScrollArrow />
      {/* Hero banner design */}
      {/* <div className="sectional herobanner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <motion.h1
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 2, ease: "easeOut" }}
                className="homepage text-with-bg"
                style={{ marginTop: "50px" }}
              >
                Your Premier Staffing Solution in Oman
              </motion.h1>
              <motion.p
                className="text-white"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 2.5, duration: 1 }}
                style={{ fontSize: "20px" }}
              >
                Delivering quality talent for your business needs, both
                permanent and temporary.
              </motion.p>

              <a
                href="/#contactus"
                type="button"
                className="mt-2 btn btn-primary btn-rounded btn-lg"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div> */}

      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        autoplay={{ delay: 5000 }}
        loop
        className="herobanner-slider"
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            className="slider-style-container"
            style={{
              background: ` url(${slide.backgroundImage}) no-repeat center center`,
            }}
          >
            <div className="main-container-slider">
              <div className="row">
                <div className="col-lg-8">
                  <motion.h1
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 2, ease: "easeOut" }}
                    className="homepage text-with-bg"
                    style={{ marginTop: "50px" }}
                  >
                    {slide.title}
                  </motion.h1>
                  <motion.p
                    className="text-white"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 2.5, duration: 1 }}
                    style={{ fontSize: "20px" }}
                  >
                    {slide.description}
                  </motion.p>

                  <button
                    className="new-style-button"
                    onClick={() => Navigate(slide.buttonLink)}
                  >
                    <img src={icon} style={{ width: "30px", height: "30px" }} />
                    <span>{slide.buttonLabel}</span>
                  </button>
                  {/* <a
                    href={slide.buttonLink}
                    type="button"
                    className="mt-2 btn btn-primary btn-rounded btn-lg"
                  >
                    {slide.buttonLabel}
                  </a> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Avation */}
      <div className="section py-5">
        <div className="container">
          <div className="row">
            {/* Image Section */}
            <div className="col-lg-6">
              <motion.div
                // src="/Assets/Aviimg.jpg"
                // className="bg-exp-data img-fluid"
                alt="What We Do"
                initial={{ x: -150, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  type: "tween",
                  ease: "easeOut",
                  duration: 3,
                }}
                viewport={{ once: true, amount: 0.3 }}
              >
                <div className="about-left-pics-2 pos-rel">
                  <img src={firstimage} className="about-2-1" />
                  <img src={Secondimage} className="about-2-2" />

                  <div className="about-exp-yr pos-rel">
                    <div>
                      <h2 className="heading-2">10+</h2>
                      <h5 className="heading-5 mb-0">Years Experience</h5>
                    </div>
                    <img src={thirdimage} className="about-exp-pic" />
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Content Section */}
            <div className="col-lg-6">
              <motion.div
                variants={fadeIn}
                initial="hidden"
                whileInView="visible"
              >
                <h6 className="mb-3">
                  {" "}
                  <img
                    src={pointer}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                  What We Do
                </h6>
                <h3
                  className="mb-4"
                  style={{
                    fontFamily: "sans-serif",
                    fontWeight: "700",
                  }}
                >
                  Leading Oman’s Workforce with Unmatched Staffing Solutions!
                </h3>
                <p style={{ marginBottom: "40px", textAlign: "justify" }}>
                  At Choice Global Business, we redefine staffing excellence.
                  <br />
                  From daily event support to monthly operational hires, our
                  high-performance permanent and temporary staffing solutions
                  ensure you get the right talent, every time. <br />
                  As Oman’s leading staffing agency, we’re your trusted partner
                  in building teams that power success. Let us help you achieve
                  more, effortlessly.
                </p>
              </motion.div>
              <motion.div
                variants={fadeIn}
                initial="hidden"
                whileInView="visible"
              >
                <h6 className="mb-3">
                  {" "}
                  <img
                    src={pointer}
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                  WHY CHOOSE US
                </h6>
                <div className="contain-container-image">
                  <div
                    className="image-container-what-we-do"
                    style={{ marginRight: "20px" }}
                  >
                    <img src={checked} className="image-what-we-do" />
                    <p className="paragraph-new"> Fast and Reliable Service</p>
                  </div>
                  <div
                    className="image-container-what-we-do"
                    style={{ marginLeft: "25px" }}
                  >
                    <img src={checked} className="image-what-we-do" />
                    <p>High-Quality Hires</p>
                  </div>
                </div>
                <div className="contain-container-image">
                  <div
                    className="image-container-what-we-do"
                    style={{ marginRight: "20px" }}
                  >
                    <img src={checked} className="image-what-we-do" />
                    <p>Expertise in Various Sectors</p>
                  </div>
                  <div
                    className="image-container-what-we-do"
                    style={{ marginLeft: "5px" }}
                  >
                    <img src={checked} className="image-what-we-do" />
                    <p>Commitment to Client Satisfaction</p>
                  </div>
                </div>
                <div className="about-contact-main-container">
                  <div className="about-contact-2">
                    <img
                      src={microphone}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                  <div
                    style={{
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      paddingTop: "10px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      borderRadius: "20px",
                    }}
                  >
                    <h5
                      style={{
                        marginBottom: "10px",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Looking for top talent?
                      <br />
                      <p style={{ marginTop: "5px" }}>Let’s connect today!</p>
                    </h5>
                    <p
                      style={{
                        fontWeight: "600",
                        color: "#0292ad",
                        fontSize: "17px",
                      }}
                    >
                      {phone}
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* manpower  */}
      {/* <div className="section two-">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="inner-two">
                
                <h3>
                  Achieve Excellence with Our Tailored <br />
                  Manpower Solutions
                </h3>
                <p>
                  Our tailored manpower solutions are crafted to meet your
                  unique staffing and workforce needs effectively. We provide
                  expert services in recruitment, employee training, and
                  workforce management to help you build a successful team.
                  Choose us to access top talent and innovative solutions that
                  drive your organization's success and growth.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src="/Assets/teamwork.jpg"
                className="bg-exp-data"
                style={{ width: "100%", height: 450 }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* services */}
      <div className="container-fluid mb-5" id="Event">
        <div class="text-center mt-5">
          <h1 className="main-heading">
            Our <span className="highlighted-text">Services</span>
          </h1>
        </div>
        <div className="row">
          <motion.div
            initial={{ x: -150, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              type: "tween",
              ease: "easeOut",
              duration: 3,
            }}
            viewport={{ once: true, amount: 0.3 }}
            className="col-md-3"
            onClick={() => handleNavigate("Permanent Staffing")}
          >
            <div className="box">
              <div className="our-services settings">
                <div className="icon">
                  <img
                    // src="https://i.imgur.com/6NKPrhO.png"
                    src={servicethree}
                    alt={"Permanent Staffing"}
                    style={{ width: "93px", height: "85px" }}
                  />
                </div>
                <h4 className="headers-more">Permanent Staffing</h4>
                <p className="paragraph-inner">
                  Build a strong, long-term team with top permanent talent.
                </p>
                <button class="custom-btn">
                  <div class="custom-btn-text">
                    <span>Explore</span>
                    <span>Now</span>
                  </div>
                  <div class="custom-btn-clone">
                    <span>Explore</span>
                    <span>Now</span>
                  </div>
                  <svg
                    stroke-width="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="custom-btn-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: -150, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              type: "tween",
              ease: "easeOut",
              duration: 3,
            }}
            viewport={{ once: true, amount: 0.3 }}
            className="col-md-3"
            onClick={() => handleNavigate("Temporary Staffing")}
          >
            <div className="box">
              <div className="our-services speedup">
                <div className="icon">
                  <img
                    // src={"https://i.imgur.com/KMbnpFF.png"}
                    src={servicetwo}
                    alt={"Temporary Staffing"}
                    style={{ width: "93px", height: "85px" }}
                  />
                </div>
                <h4 className="headers-more">Temporary Staffing</h4>
                <p className="paragraph-inner">
                  Flexibility at its best—hire skilled professionals for
                  short-term needs.
                </p>
                <button class="custom-btn">
                  <div class="custom-btn-text">
                    <span>Explore</span>
                    <span>Now</span>
                  </div>
                  <div class="custom-btn-clone">
                    <span>Explore</span>
                    <span>Now</span>
                  </div>
                  <svg
                    stroke-width="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="custom-btn-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: -150, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              type: "tween",
              ease: "easeOut",
              duration: 3,
            }}
            viewport={{ once: true, amount: 0.3 }}
            className="col-md-3"
            onClick={() => handleNavigate("Event Staffing")}
          >
            <div className="box ">
              <div className="our-services privacy">
                <div className="icon">
                  <img
                    // src={"https://i.imgur.com/AgyneKA.png"}
                    src={servicefour}
                    style={{ width: "93px", height: "85px" }}
                    alt={"Event Staffing"}
                  />
                </div>
                <h4 className="headers-more">
                  Event
                  <br /> Staffing
                </h4>
                <p className="paragraph-inner">
                  Ensure your events shine with expertly trained
                  <br /> staff.
                </p>
                <button class="custom-btn">
                  <div class="custom-btn-text">
                    <span>Explore</span>
                    <span>Now</span>
                  </div>
                  <div class="custom-btn-clone">
                    <span>Explore</span>
                    <span>Now</span>
                  </div>
                  <svg
                    stroke-width="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="custom-btn-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: -150, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              type: "tween",
              ease: "easeOut",
              duration: 3,
            }}
            viewport={{ once: true, amount: 0.3 }}
            className="col-md-3 "
            onClick={() => handleNavigate("Business Staffing")}
          >
            <div className="box ">
              <div className="our-services backups">
                <div className="icon">
                  <img
                    // src={"https://i.imgur.com/vdH9LKi.png"}
                    src={serviceone}
                    alt={"Business Staffing"}
                    style={{ width: "93px", height: "85px" }}
                  />
                </div>
                <h4 className="headers-more">Business Staffing</h4>
                <p className="paragraph-inner">
                  Empower your business with the right talent for
                  every operation.
                </p>
                <button class="custom-btn">
                  <div class="custom-btn-text">
                    <span>Explore</span>
                    <span>Now</span>
                  </div>
                  <div class="custom-btn-clone">
                    <span>Explore</span>
                    <span>Now</span>
                  </div>
                  <svg
                    stroke-width="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="custom-btn-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                  >
                    <path
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      {/* old code for service */}
      {/* <div className="  sliderServices  py-5" id="Event">
        <div className="container  col-12   ">
          <div className=" align-items-center  justify-content-center   mb-3">
            <h1 className="Severour text-center">Our Services</h1>
          </div>
          <div className="row">
            <div className="col-md-6  d-flex justify-content-center  atcentre">
              <div>
                <div
                  className=" d-flex boxservices"
                  onClick={() => toggleContent("permanent")}
                >
                  <RiArrowRightSLine className="footericon" />
                  <p className=" footerstextservices">Permanent Staffing</p>
                </div>
                {activeService === "permanent" && (
                  <div className="service-content p-3 text-white  boxservices">
                    <p>
                      Finding the right talent is essential for the success and
                      growth of your organization. At Choice Global, we
                      specialize in delivering top notch permanent staffing
                      solutions tailored to your unique needs. With a deep
                      understanding of the talent landscape and industry
                      requirements, we connect businesses with skilled
                      professionals who are ready to make a lasting impact.
                    </p>
                    <p>Our Approach</p>
                    <ul>
                      <li>Understanding Your Needs</li>
                      <li>Sourcing Top Talent</li>
                      <li>Thorough Screening Process</li>
                      <li>Seamless Integration</li>
                    </ul>
                  </div>
                )}

                <div
                  className=" d-flex boxservices mt-3"
                  onClick={() => toggleContent("temporary")}
                >
                  <RiArrowRightSLine className="footericon" />
                  <p className=" footerstextservices">Temporary Staffing</p>
                </div>
                {activeService === "temporary" && (
                  <div className="service-content p-3 text-white  boxservices">
                    <p>
                      In today’s fast-paced business world, workforce needs can
                      change at a moment’s notice. At Choice Global, we offer
                      reliable and efficient temporary staffing solutions to
                      help you address short-term demands, seasonal peaks, and
                      project-based requirements. Whether you need a single temp
                      or an entire team, we’ve got you covered.
                    </p>
                    <p>Our Approach</p>
                    <ul>
                      <li>Understanding Your Workforce Needs</li>
                      <li>Quick and Reliable Talent Deployment</li>
                      <li>End-to-End Support:</li>
                    </ul>
                  </div>
                )}

                <div
                  className=" d-flex boxservices mt-3"
                  onClick={() => toggleContent("event")}
                >
                  <RiArrowRightSLine className="footericon" />
                  <p className=" footerstextservices">Event Staffing</p>
                </div>
                {activeService === "event" && (
                  <div className="service-content p-3 text-white  boxservices">
                    <p>
                      At Choice Global, we understand that the success of any
                      event hinges on having the right team in place. Our event
                      staffing solutions are designed to provide you with
                      skilled, professional, and personable staff who bring your
                      event vision to life. Whether it’s a corporate conference,
                      a private celebration, or a large-scale festival, we have
                      the expertise to ensure everything runs smoothly.
                    </p>
                    <p>Our Approach</p>
                    <ul>
                      <li>Pre-Event Planning and Coordination</li>
                      <li>Comprehensive Staffing Solutions</li>
                      <li>Professional Training and Management</li>
                    </ul>
                  </div>
                )}

                <div
                  className="d-flex  d-flexc boxservices mt-3"
                  onClick={() => toggleContent("business")}
                >
                  <RiArrowRightSLine className="footericon" />
                  <p className=" footerstextservices">Business Staffing</p>
                </div>

                {activeService === "business" && (
                  <div className="service-content p-3 text-white  boxservices">
                    <p>
                      Your workforce is the backbone of your business. At Choice
                      Global, we specialize in providing customized business
                      staffing solutions to help organizations build
                      high-performing teams. Whether you’re looking to fill
                      critical roles, scale your workforce, or access
                      specialized skills, our staffing expertise ensures you
                      have the right talent to achieve your goals.
                    </p>
                    <p>What We Offer</p>
                    <ul>
                      <li>Temporary Staffing</li>
                      <li>Permanent Staffing</li>
                      <li>Contract-to-Hire</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6  d-flex justify-content-center  atcentre">
              <div className=""></div>
            </div>
          </div>
        </div>
      </div> */}
      {/* count design */}

      {/* <div className="section-two count-design">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="count-inner counter-center">
                <div className="d-flex">
                  <img
                    src="/Assets/place.png"
                    width="70"
                    height="70"
                    alt=""
                    color="white"
                  />
                  <h2>
                    <CountUp end={50} duration={12} style={{ fontSize: 40 }} />{" "}
                    +
                  </h2>
                </div>
                <p>Our Placements</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="count-inner counter-center">
                <div className="d-flex">
                  <img
                    src="/Assets/premiumbadge.png"
                    width="70"
                    height="68"
                    alt=""
                  />
                  <h2>
                    <CountUp end={10} duration={12} style={{ fontSize: 40 }} />{" "}
                    +
                  </h2>
                </div>
                <p>Awards</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="count-inner counter-center">
                <div className="d-flex">
                  <img
                    src="/Assets/recruitment.png"
                    width="70"
                    height="70"
                    alt=""
                  />
                  <h2>
                    <CountUp end={76} duration={12} style={{ fontSize: 40 }} />{" "}
                    +
                  </h2>
                </div>
                <p>Recruitment</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* industries we serve */}
      <Industries />

      {/* {mission} */}

      <div className="">
        <div className="">
          <MissionVisionValues />
        </div>
      </div>

      {/* <HowWeWork /> */}
      <Testmonials />

      <div className="contactus-section" id="contactus">
        <EnquiryForm />
      </div>
      <FAQ />
    </>
  );
}
