import React from "react";
import { useLocation } from "react-router-dom";
import caller from "../components/Assets/contact.png";

export default function Header() {
  const location = useLocation();
  const currentPath = location.pathname;
  const phone = localStorage.getItem("phone");
  const isInteger = /^\+?\d[\d\s-]*$/.test(phone);
  return (
    <nav
      className="head-footer-bg  heighthead sticky-header navbar navbar-expand-lg"
      id="customnavbar"
    >
      <div className="container fullwidth">
        <a href="/" className="navbar-brand">
          <img src="/Assets/Logo.png" width="250" height="55" alt="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            {" "}
            {/* Align items to the right */}
            <li className="nav-item me-4">
              <a
                className={`nav-a ${currentPath === "/" ? "active" : ""}`}
                href="/"
              >
                HOME
              </a>
            </li>
            <li className="nav-item dropdown me-4">
              {/* <a
                className="nav-a "
                href="/service"
                role="button"
               data-bs-toggle="dropdown"
                aria-expanded="false"
              > */}
              <a
                className="nav-a "
                href="/service"
                role="button"
                // data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                SERVICES
              </a>
              {/* <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="">
                    Permanent Staffing
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/service">
                    Temporary Staffing
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/service">
                    Event Staffing
                  </a>
                </li>

                <li>
                  <a className="dropdown-item" href="/service">
                    Business Staffing
                  </a>
                </li>
              </ul> */}
            </li>
            {/* <li className="nav-item me-4">
              <a
                className={`nav-a ${
                  currentPath === "/Aviation" ? "active" : ""
                }`}
                href="/Aviation"
              >
                CAREER
              </a>
            </li> */}
            <li className="nav-item me-4">
              <a
                className={`nav-a ${
                  currentPath === "/Manpower" ? "active" : ""
                }`}
                href="/careers"
              >
                CAREERS
              </a>
            </li>
            {/* <li className="nav-item"> */}
            <div
              style={{
                display: "flex",
                gap: "5px",
                marginTop: "10px",
              }}
            >
              {isInteger ? (
                <img
                  src={caller}
                  style={{ height: "20px", width: "20px", marginTop: "8px" }}
                />
              ) : null}
              <p
                style={{
                  color: "black",
                  marginTop: "8px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                {phone}
              </p>
            </div>
            <div class="button-container">
              <a
                style={{
                  color: "#184dae",
                  fontFamily: "DM sans,Arial,Helvetica,sans-serif",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                className={`nav-a ${
                  currentPath === "/contact-us" ? "active" : ""
                }`}
                href="/contactUs"
              >
                <button class="hover-button">Contact Us</button>
              </a>
            </div>
            {/* </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
